var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', _vm._l(_vm.schedules, function (schedule, index) {
    return _c('v-switch', {
      key: index,
      attrs: {
        "label": "Send out if expiry date is less then or equal ".concat(schedule.interval_value, " day(s)")
      },
      model: {
        value: _vm.form.schedules[index].active,
        callback: function callback($$v) {
          _vm.$set(_vm.form.schedules[index], "active", $$v);
        },
        expression: "form.schedules[index].active"
      }
    });
  }), 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }