<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-switch
          v-for="(schedule, index) in schedules"
          :key="index"
          v-model="form.schedules[index].active"
          :label="`Send out if expiry date is less then or equal ${schedule.interval_value} day(s)`" />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean, Array],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('schedules', null),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)
    const schedules = ref(form.value.schedules)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      schedules,
      validate,
    }
  },
}
</script>
